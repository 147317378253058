import {
    Component,
    ComponentOptions,
    IBuildingQuerySuggestArgs,
    IComponentBindings,
    InitializationEvents,
    IQuerySuggestRequest,
    OmniboxEvents,
    QueryEvents,
    QueryBuilder,
    IDoneBuildingQueryEventArgs,
    Initialization,
    IStringMap,
} from 'coveo-search-ui';

import { ISitecoreContextInitializationEventArgs, SitecoreContextEvents } from '../../events/ContextEvents';
import { IExternalFieldConfig as ILegacyExternalFieldConfig } from '../../handlers/CoveoFieldsHandler';
import { IExternalFieldConfig, ISourceSpecificFieldConfig } from '../../handlers/IFieldTypeResolver';

export interface ICoveoForSitecoreContextOptions {
    scItemId: string;
    scItemUri: string;
    scLatestVersionFieldName: string;
    scLanguageFieldName: string;
    scCurrentLanguage: string;
    scRestEndpointUri: string;
    scLabels: IStringMap<string>;
    scPageName: string;
    scDefaultPipelineName: string;
    scShouldHaveAnalyticsComponent: boolean;
    scAnalyticsEnabled: boolean;
    scIsUserAnonymous: boolean;
    scFieldPrefix: string;
    scFieldSuffix: string;
    scExternalFields: IExternalFieldConfig[] | ILegacyExternalFieldConfig[];
    scSourceSpecificFields: ISourceSpecificFieldConfig[];
    scPreferSourceSpecificFields: boolean;
    scIndexSourceName: string;
    scIsInExperienceEditor: boolean;
    scMaximumAge: number;
    scPageNameFullPath: string;
    scSiteName: string;
}

export class CoveoForSitecoreContext extends Component {
    static ID = 'ForSitecoreContext';

    static options: ICoveoForSitecoreContextOptions = {
        scItemId: ComponentOptions.buildStringOption(),
        scItemUri: ComponentOptions.buildStringOption(),
        scLanguageFieldName: ComponentOptions.buildStringOption(),
        scLatestVersionFieldName: ComponentOptions.buildStringOption(),
        scCurrentLanguage: ComponentOptions.buildStringOption(),
        scRestEndpointUri: ComponentOptions.buildStringOption(),
        scLabels: ComponentOptions.buildJsonOption(),
        scPageName: ComponentOptions.buildStringOption(),
        scDefaultPipelineName: ComponentOptions.buildStringOption(),
        scShouldHaveAnalyticsComponent: ComponentOptions.buildBooleanOption(),
        scAnalyticsEnabled: ComponentOptions.buildBooleanOption(),
        scIsUserAnonymous: ComponentOptions.buildBooleanOption(),
        scFieldPrefix: ComponentOptions.buildStringOption(),
        scFieldSuffix: ComponentOptions.buildStringOption(),
        scExternalFields: ComponentOptions.buildJsonOption<IExternalFieldConfig[] | ILegacyExternalFieldConfig[]>(),
        scSourceSpecificFields: ComponentOptions.buildJsonOption<ISourceSpecificFieldConfig[]>(),
        scPreferSourceSpecificFields: ComponentOptions.buildBooleanOption(),
        scIndexSourceName: ComponentOptions.buildStringOption(),
        scIsInExperienceEditor: ComponentOptions.buildBooleanOption(),
        scMaximumAge: ComponentOptions.buildNumberOption(),
        scPageNameFullPath: ComponentOptions.buildStringOption(),
        scSiteName: ComponentOptions.buildStringOption(),
    };

    constructor(
        public element: HTMLElement,
        public options: ICoveoForSitecoreContextOptions,
        public bindings: IComponentBindings
    ) {
        super(element, CoveoForSitecoreContext.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, CoveoForSitecoreContext, options);
        this.bind.onRootElement(InitializationEvents.afterComponentsInitialization, this.afterComponentsInitialization);
        this.bind.onRootElement(QueryEvents.doneBuildingQuery, this.doneBuildingQuery);
        this.bind.onRootElement(OmniboxEvents.buildingQuerySuggest, this.buildingQuerySuggest);
    }

    private doneBuildingQuery(args: IDoneBuildingQueryEventArgs): void {
        if (this.hasDefaultPipelineNameSet() && this.queryBuilderUsesDefaultPipeline(args.queryBuilder)) {
            args.queryBuilder.pipeline = this.options.scDefaultPipelineName;
        }
    }

    private buildingQuerySuggest(args: IBuildingQuerySuggestArgs): void {
        if (this.hasDefaultPipelineNameSet() && this.querySuggestUsesDefaultPipeline(args.payload)) {
            args.payload.pipeline = this.options.scDefaultPipelineName;
        }
    }

    private hasDefaultPipelineNameSet(): boolean {
        return this.options.scDefaultPipelineName !== '';
    }

    private queryBuilderUsesDefaultPipeline(queryBuilder: QueryBuilder): boolean {
        return typeof queryBuilder.pipeline === 'undefined';
    }

    private querySuggestUsesDefaultPipeline(querySuggestRequest: IQuerySuggestRequest): boolean {
        return typeof querySuggestRequest.pipeline === 'undefined';
    }

    private afterComponentsInitialization(): void {
        const contextArgs: ISitecoreContextInitializationEventArgs = {
            sitecoreItemId: this.options.scItemId,
            sitecoreItemUri: this.options.scItemUri,
            sitecorePageName: this.options.scPageName,
            shouldHaveAnalyticsComponent: this.options.scShouldHaveAnalyticsComponent,
            analyticsEnabled: this.options.scAnalyticsEnabled,
            indexSourceName: this.options.scIndexSourceName,
            currentLanguage: this.options.scCurrentLanguage,
            languageFieldName: this.options.scLanguageFieldName,
            latestVersionFieldName: this.options.scLatestVersionFieldName,
            isInExperienceEditor: this.options.scIsInExperienceEditor,
            sitecorePageFullPath: this.options.scPageNameFullPath,
            siteName: this.options.scSiteName,
            restEndpointUri: this.options.scRestEndpointUri,
        };
        this.bind.trigger(this.element, SitecoreContextEvents.onSitecoreContextInitialization, contextArgs);
    }
}

Initialization.registerAutoCreateComponent(CoveoForSitecoreContext);
